import React, { useState, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const faqItems = [
  { title: `You askin' what we've done, partner? Well, let me tell ya!`, content: `<div>We ain't just talk; we've worked on all sorts of projects, from manufacturing to fintech, publishing to government. Yeah, that's right. We specialise in designing and constructing software, the kind that'll solve your specific business problems.</div>` },
  {
    title: `You want to know how we build applications, huh? I hope you're ready, friend.`, content: `<div>We don't mess around. We follow the steps that have proven themselves time and again. You got that? Here's what we do:</div>
  <span>We gather and analyse requirements, 'cause that's how you start.</span>
  <span>We design the solution and its architecture, and we don't cut corners.</span>
  <span>We code the solution, and we code it right.</span>
  <span>We test the solution. We don't need no surprises.</span>
  <span>We deploy and maintain that solution like it's nobody's business.</span>
  <div>And we do all this within the agile framework, you hear me? We give our customers something to chew on every few weeks. We listen to what they say, and we make it right.</div>` },
  {
    title: `You want to know our specialties, huh? You want to know where we shine? Sit down and take notes, 'cause here it comes:`, content: `<div>We're all about the Microsoft platform, partner. You know what that means? It means we master these:</div>
  <span>Web: MVC, ASP.NET, Angular, JQuery, Bootstrap, JavaScript, HTML, CSS. You get it?</span>
  <span>Server: C#, C++, Powershell, Visual Basic .Net, VB6. We ain't playing games here.</span>
  <span>Database: SQL Server, Oracle, MySQL. You want it, we handle it.</span>
  <div>But don't think we're all about Microsoft, friend. We've dipped our toes into other platforms like Linux, and we didn't get burned.</div>` },
  {
    title: `You think we just throw things together without talking? Think again, pal!`, content: `<div>Communication? It's the key, the lock, and the door to success around here. You ain't getting anywhere without it.</div>
  <div>We got the active stuff:</div>
  <span>Face-to-face meetings, 'cause sometimes you gotta look 'em in the eyes.</span>
  <span>Video conferencing, 'cause not everyone's in the same room.</span>
  <span>Telephone conferencing, 'cause sometimes, you just gotta hear 'em.</span>
  <span>Webinars and stand-up presentations. We ain't hiding behind screens.</span>
  <div>And we got the passive stuff too:</div>
  <span>Email, for when you gotta put it in writing.</span>
  <span>Online scrum board, to keep everyone in line.</span>
  <span>Project progress reports, so everyone knows where we stand.</span>
  <div>That's how we do it, partner. No games, no tricks.</div>` },
  {
    title: `You want to know what files you'll get your hands on, do ya? Well, brace yourself, 'cause here's the list, and it ain't for the faint-hearted:`, content: `<div>You'll get access to everything, pal. Every document, every plan. Don't believe me? Here's what you'll see:</div>
  <span>Project Charter, 'cause that's where it all starts.</span>
  <span>Project management plan, your guide to everything we're doing.</span>
  <span>Project scope plan, so you know exactly what's in and what's out.</span>
  <span>Project schedule plan, to keep us all on track.</span>
  <span>Quality assurance plan, 'cause we don't mess around with quality.</span>
  <span>Project risks plan, 'cause sometimes things don't go smooth.</span>
  <div>You'll see it all, friend. Every detail, every plan. We're not hiding anything.</div>` },
  { title: `You're asking how you fit into this, huh? You think this is some mystery? Let me clear it up for you, partner:`, content: `<div>You're right there in the center of it all. We're here to satisfy your needs, your requirements. And we ain't doing it without you. You'll be there with us, gathering the requirements, laying it all out for the team, and putting your stamp of approval on the deliverables as we move through this thing. Daunting? Maybe for some, but not for you, not with us. We'll walk this path together, and you better believe we'll make it to the end.</div>` },
  { title: `Not satisfied? Let me tell you something, partner:`, content: `<div>We ain't in the business of leaving customers unhappy. You think this is a game? Our process is about short sprints, quick checks, and real feedback. We'll give you every chance to try the product, to poke it and prod it, to tell us what you think. And if something ain't right, you tell us. We'll change course. We'll make it right. Satisfaction ain't a maybe with us; it's a promise. So don't you worry, friend. We've got this.</div>` },
  { title: `What makes you my best choice?`, content: `<div>We believe we are your best choice because we are driven to satisfy our customers with quality products that meet thier requirements.</div>` },
  { title: `You wanna know what makes us the best choice? You really think there's someone else out there who can do what we do?`, content: `<div>Listen up, 'cause I'm only saying this once: We're driven. We're relentless. We're here to give you quality, not half-baked nonsense that some other outfit might try to shove down your throat. You have requirements? We'll meet 'em. You have needs? We'll satisfy 'em. We're not here to play games; we're here to get the job done. That's why we're your best choice. Any other questions, or can we finally get to work?</div>` },
  { title: `You want to talk money, huh? You think this is just about dollars and cents? Well, alright then:`, content: `<div>We're talking £600 a day here, but don't think that's set in stone. We know projects differ, needs change. We're willing to negotiate, friend, to make sure the rates fit the project, the type, the duration. We're not here to take you for a ride; we're here to get the job done.</div>` },
  { title: `You wanna know what we're capable of, huh? Think we're some one-trick ponies? Well, think again, pal:`, content: `<div>We're talking C, C++, C#, Visual Basic, Java, JavaScript, SQL. Yeah, that's right, we've got it all. You want something on Windows? We can do it. You need something for Android? We've got that too. We're not playing games here, friend. We're serious, we're skilled, and we're ready to get the job done.</div>` },
  { title: `Location? You think that's a problem for us? Let me tell you something, friend:`, content: `<div>We're stationed in Chippenham, but we ain't tied down. We'll travel if we need to, go where the job takes us. Remote work? We've got that down too. We're not stuck in one place, and we're not afraid to move. We go where the work is, and we do what needs doing.</div>` },
  {
    title: `QA process? You think we just wing it and hope it all turns out okay? You must be out of your mind. Let me lay it out for you, and you better pay attention:`, content: `<div>We've got a process here, a tight, step-by-step, no-nonsense process:</div>
  <span>Review of requirements, 'cause we need to know what we're doing.</span>
  <span>Test planning and writing test cases, 'cause we don't just make it up as we go along.</span>
  <span>Unit testing, to break it down piece by piece.</span>
  <span>Integration testing, to make sure all those pieces fit together.</span>
  <span>Performance testing, 'cause it's gotta run smooth.</span>
  <span>Security testing, 'cause we're not leaving any doors open.</span>
  <span>Cross-browser testing and cross-platform testing, 'cause it's gotta work everywhere.</span>
  <span>Updating test cases, 'cause things change.</span>
  <span>Regression testing, to make sure we didn't break anything along the way.</span>
  <div>We don't mess around, friend. We make sure it's done right.</div>` },
  { title: `Fully automated deployment system? What do you take us for, amateurs? Listen up, 'cause I ain't repeating myself:`, content: `<div>Yes, we use Azure build and release pipelines. We're talking continuous integration, continuous deployment. We're not doing this by hand like it's the Stone Age. We're up-to-date, we're on the ball, and we've got it all under control.</div>` },
  { title: `Full suite of automated tests? You think we're playing around here? Listen up, partner:`, content: `<div>Yes, you heard me right, we build and use a fully automated, end-to-end testing system. Every single step of the way, from start to finish. We don't cut corners, and we don't mess around. The technologies? They depend on the targeted infrastructure, but that ain't a problem for us. We'll do whatever it takes to get it right.</div>` },
];

const FAQ = () => {

  const [activeIndex, setActiveIndex] = useState(null);
  const [filterText, setFilterText] = useState('');

  const clearFilter = () => {
    setFilterText('');
  };

  const toggleDiv = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const highlightFilteredText = (text) => {
    if (!filterText) {
      return text;
    }
    return text.replace(new RegExp(filterText, 'gi'), (match) => `<strong>${match}</strong>`);
  };

  const filteredFaqItems = useMemo(() => {
    if (!filterText || filterText.trim().length === 0) {
      return faqItems;
    }

    return faqItems.filter(item => {
      const title = item.title.toLowerCase();
      const content = item.content.toLowerCase();
      return title.includes(filterText.toLowerCase()) || content.includes(filterText.toLowerCase());
    });
  }, [filterText]);

  return (
    <div className='content faq'>
      <h1>Frequently Asked Questions</h1>
      <p>
        You'll find a list of the most frequently asked questions below, but don't think that's the end of it.
        If you've got more questions, if you've got something that's been eating at you, you just march on over to our <a href="/contact">Contact</a> page and ask.
        We're not hiding, we're not running. We're here, and we'll answer whatever you've got.
      </p>
      <div>
        <span>Filter:</span>
        <TextField
          value={filterText}
          onChange={e => setFilterText(e.target.value)}
          placeholder="Enter search filter"
          classes={{ root: 'faq-filter' }}
          InputProps={{
            endAdornment: (
              (
                <InputAdornment position="end">
                  <IconButton onClick={clearFilter} disabled={!filterText}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              )
            ),
          }}
        />
      </div>
      <ul>
        {filteredFaqItems.map((item, index) => (
          <li key={index} onClick={() => toggleDiv(index)} className={activeIndex === index || (filterText && filterText.trim().length > 0) ? "active" : ""}>
            <span dangerouslySetInnerHTML={{ __html: highlightFilteredText(item.title) }}></span>
            <div dangerouslySetInnerHTML={{ __html: highlightFilteredText(item.content) }} />
          </li>
        ))}
      </ul>
      <p>
        Didn't find what you were after?
        What's the matter with you, can't find your way?
        Well, let me tell you something, pal: You just head on over to our <a href="./contact">Contact</a> page and get in touch.
        Don't wait, don't hesitate, don't mess around.
        We're here, and we're waiting for you.
      </p>
    </div>
  );
};

export default FAQ;
