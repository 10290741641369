import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';

const StyledApp = styled.div``;

const App = () => (
  <StyledApp className='app'>
    <Header />
    <Main />
    <Footer />
  </StyledApp>
);

export default App;
