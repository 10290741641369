import React from 'react';

const About = () => (
  <div className='content about'>
    <h1>About Softcaw Limited – Where Innovation Meets Execution, and Dreams Meet Reality</h1>

    <p>We're a software company that means business. We build custom, full-stack software solutions that don't just streamline; they redefine your business processes.</p>
    <p>Customer satisfaction? That's our game, friend. We don't just aim for satisfaction; we hit it dead center, every time.</p>
    <p>You want quality? Look no further. Quality in functionality, delivery, and support – that's not just what we do; it's who we are. Your trust in us won't be misplaced.</p>
    <p>Ready to take the next step? Head over to our <a href="./contact">Contact</a> page. We're waiting, and we don't like to be kept waiting.</p>
  </div>
);

export default About;
