import React from 'react';

const Home = () => (
  <div className='content home'>
    <h1>Welcome to Softcaw – Where We Don't Just Talk Code, We Speak Success</h1>
    <p>
      You're not looking at just another software company. This is Softcaw, specialising in full-stack solutions that go beyond mere code; we deliver triumph from server to browser.
    </p>
    <p>
      Want to streamline your business processes or interface with your factory? Want to make more than a splash online? We don't just provide services; we craft legends in analysing, designing, developing, and delivering quality-approved software.
    </p>
    <p>
      Think I'm bluffing? Take a peek at Softcaw's unbreakable dedication and our no-holds-barred commitment to quality on our <a href="./about">About</a> page. That's where talk ends and action begins.
    </p>
    <p>
      Don't wander like a lost pup. Head over to our <a href="./service">Services</a> page to explore the arsenal of technologies we wield, along with the full range of services that'll make your competitors eat dust.
    </p>
    <p>
      Got questions? Our <a href="./faq">FAQ</a> page might have the answers. But don't take too long; time waits for no one, especially not in business.
    </p>
    <p>
      Enough chit-chat. Reach out on our <a href="./contact">Contact</a> page. Let's join forces and turn those ideas into an unstoppable force of reality. Ready to make history? Let's do it.
    </p>
  </div>
);

export default Home;
