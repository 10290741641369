import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import CookieWarning from './content/CookieWarning';
import TermsOfUse from './content/TermsOfUse';
import PrivacyPolicy from './content/PrivacyPolicy';
import SocialNetworks from './content/SocialNetworks';


const Footer = () => {
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showSocialNetworks, setShowSocialNetworks] = useState(false);

  const toggleTermsOfUse = (e) => {
    if (e) e.preventDefault(); 
    setShowTermsOfUse(!showTermsOfUse);
  };

  const togglePrivacyPolicy = (e) => {
    if (e) e.preventDefault(); 
    setShowPrivacyPolicy(!showPrivacyPolicy);
  };

  const toggleSocialNetworks = (e) => {
    // Prevent the default link behavior
    if (e) e.preventDefault(); 
    setShowSocialNetworks(!showSocialNetworks);
  };

  const switchToPrivacyPolicy = (e) => {
    if (e) e.preventDefault(); 
    setShowTermsOfUse(false);
    setShowPrivacyPolicy(true);
  };

  return (
    <footer>
      <ul>
        <li><NavLink to="./home">Home</NavLink></li>
        <li><NavLink to="./about">About</NavLink></li>
        <li><NavLink to="./service">Services</NavLink></li>
        <li><NavLink to="./faq">FAQ</NavLink></li>
        <li><NavLink to="./contact">Contact</NavLink></li>
      </ul>
      <ul>
        <li><NavLink to="./#" onClick={toggleTermsOfUse}>terms of use</NavLink></li>
        <li><NavLink to="./#" onClick={togglePrivacyPolicy}>privacy policy</NavLink></li>
        <li><NavLink to="./#" onClick={toggleSocialNetworks}>social networks</NavLink></li>
        <li><span>©2023 Softcaw Limited</span></li>
      </ul>
      <CookieWarning />
      <TermsOfUse show={showTermsOfUse} onClose={toggleTermsOfUse} onPrivacyPolicy={switchToPrivacyPolicy} />
      <PrivacyPolicy show={showPrivacyPolicy} onClose={togglePrivacyPolicy} />
      <SocialNetworks show={showSocialNetworks} onClose={toggleSocialNetworks} />
    </footer>
  );

};

export default Footer;
