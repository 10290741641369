import React, { useState, useEffect } from 'react';

const CookieWarning = () => {
  const [showModal, setShowModal] = useState(false);

  const handleAcknowledge = () => {
    document.cookie = "cookiesAccepted=true; max-age=31536000; path=/";
    setShowModal(false);
  };

  useEffect(() => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    const hasAcceptedCookies = cookies.some(cookie => cookie === "cookiesAccepted=true");
    setShowModal(false);

    if (!hasAcceptedCookies) {
      setTimeout(() => {
        setShowModal(true);
      }, 1000);
    }
  }, []);

  return (
    <div className={`cookie-warning ${showModal ? 'show' : ''}`}>
      <p>This joint's runnin' with cookies to make sure you're ridin' smooth.
        <a href="https://www.cookiesandyou.com/" target="CookieInfo" alt="Get the lowdown on Cookies here">Get the lowdown here.</a></p>
      <button onClick={handleAcknowledge}>Got it!</button>
    </div>
  );
};

export default CookieWarning;
