import React from 'react';

const Service = () => (
  <div className='content service'>
    <h1>Software Development – Where Softcaw Crafts Legends</h1>
    <p>Fullstack software development on the Microsoft Windows platform? That's just where we start, friend. We make that platform sing, dance, and obey our every command.</p>
    <p>20 years in the trenches, designing, building, and delivering for the web, desktop, and mobile. .Net framework in C#? SQL databases? Android and Xamarin? That's our playground, and we rule it with an iron fist.</p>
    <p>Our rates? £600 per day or £80 per hour. Sounds steep? You think Harry comes cheap? We deliver designs and constructions that are as rock-solid, reliable, maintainable and as a trusty as a Swiss watch, endlessly ticking precisely. We're worth every single penny, and we'll prove it.</p>
    <p>Enough talk. Ready to witness what real software development looks like? Get in contact on our <a href="/contact">Contact</a> page. Let us show you how we turn ordinary processes into streamlined success stories. But only if you're serious about success.</p>
  </div>
);

export default Service;
