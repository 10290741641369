import React from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const SocialNetworks = ({ show, onClose }) => {
    return show ? (
        <div className="modal-general">
            <div className="modal-body">
                <div className="modal-header">
                    <h4 className="modal-title">Social Networks</h4>
                    <button onClick={onClose} className='close'>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="social-networks">
                        <div className="twitter-x">
                            <a className="btn" href="https://twitter.com/softcaw" role="button" target="twitter">
                                <svg viewBox="0 0 24 24"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>
                                (formerly <TwitterIcon />Twitter)
                            </a>
                        </div>
                        <div className="linkedin">
                            <a className="btn" href="https://www.linkedin.com/in/clive-wilson-380303a/" role="button" target="linkedin">
                                <LinkedInIcon />LinkedIn
                            </a>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>) : null;
};

export default SocialNetworks;
