import React from 'react';
import { NavLink } from 'react-router-dom';

const Menu = ({ isHamburgerMenu, toggleMenu }) => (
  <div>
    <ul>
      <li onClick={toggleMenu}><NavLink to="/home" className={x => x.isActive ? 'active' : ''}>Home</NavLink></li>
      <li onClick={toggleMenu}><NavLink to="/about" className={x => x.isActive ? 'active' : ''}>About</NavLink></li>
      <li onClick={toggleMenu}><NavLink to="/service" className={x => x.isActive ? 'active' : ''}>Services</NavLink></li>
      <li onClick={toggleMenu}><NavLink to="/faq" className={x => x.isActive ? 'active' : ''}>FAQ</NavLink></li>
      <li onClick={toggleMenu}><NavLink to="/contact" className={x => x.isActive ? 'active' : ''}>Contact</NavLink></li>
    </ul>
  </div>
);

export default Menu;
