import React, { useState } from 'react';
import Logo from './Logo';
import Menu from './Menu';
import HamburgerMenu from './HamburgerMenu';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    console.log(`isMenuOpen:={isMenuOpen}`);
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className='nav'>
      <Logo />
      <div className="menu">
        <Menu isHamburgerMenu={isMenuOpen} />
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <HamburgerMenu />
      </div>
      {isMenuOpen && <div className="hamburger-menu"><Menu isHamburgerMenu={true} toggleMenu={toggleMenu} /></div>}
    </header>
  );
};

export default Header;
