import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import About from './content/About';
import Home from './content/Home';
import Service from './content/Service';
import FAQ from './content/FAQ';
import Contact from './content/Contact';

const Main = () => (
  <div>
    <Routes>
      <Route path="/home" element={<Home/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/service" element={<Service/>} />
      <Route path="/faq" element={<FAQ/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="*" element={<Navigate to="home" replace/>} />
    </Routes>
  </div>
);

export default Main;
