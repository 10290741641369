import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  const [emailAddress, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [message, setMessage] = useState('');
  const [isValidMessage, setIsValidMessage] = useState(false);
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const softcawContactApiUrl = 'https://api.softcaw.co.uk/contact';
  const softcawRecaptchaSiteKey = '6LdsZboUAAAAAN8NCL_4kR1hUNCpuPNiDMt58Po8';

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value));
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setIsValidMessage(e.target.value.trim().length > 20);
  };

  const handleTelephoneChange = (e) => {
    setTelephoneNumber(e.target.value);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    navigate('/home');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidEmail && isValidMessage) {
      window.grecaptcha.ready(async () => {
        window.grecaptcha.execute(softcawRecaptchaSiteKey, { action: 'submit' })
          .then(async (token) => {
            console.log("token: ", token);
            try {
              const response = await fetch(softcawContactApiUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ emailAddress, telephoneNumber, message, token }),
              });
              if (response.ok) {
                console.log('Message sent successfully');
              } else {
                console.error('Failed to send message', await response.json());
              }
            } catch (err) {
              console.error('An error occurred while sending the message', err);
            }
          });
      });
      setIsModalVisible(true);
    }
  };

  return (
    <div className='content contact'>
      <h1>Contact us</h1>
      <p>Make my day. Fill in those blanks and hit submit. I'll be waitin'.</p>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="emailAddress">Your Handle (Email)</label>
          <small id="emailHelp">Your secret's safe with me. No sharin'.</small>
          <input id="emailAddress" placeholder="Where can I find you?" type="email" maxLength={250} value={emailAddress} onChange={handleEmailChange} required className={isValidEmail ? "required-satisfied" : "required-not-satisfied"} />
          <div className="alert alert-danger" style={{ visibility: isValidEmail ? 'hidden' : 'visible' }}>I need a real email, not some make-believe one.</div>
        </div>
        <div>
          <label htmlFor="telephoneNumber">Ring Me Up (Phone Number)</label>
          <small id="numberHelp">Don't worry, I won't blab about this number.</small>
          <input id="telephoneNumber" name="telephoneNumber" placeholder="Got a number?" type="tel" onChange={handleTelephoneChange} />
        </div>
        <div>
          <label htmlFor="message">What's Bothering You?</label>
          <textarea id="message" name="message" rows="3" value={message} onChange={handleMessageChange} maxLength={2000} required className={isValidMessage ? "required-satisfied" : "required-not-satisfied"} placeholder="I'm listenin', but don't just whisper in the wind. Make it count with at least 20 characters, or don't bother at all." />
          <div className="alert alert-danger" style={{ visibility: isValidMessage ? 'hidden' : 'visible' }}>I'm listenin', but you gotta say somethin' first.</div>
        </div>
        <button id="submit" name="submit" type="submit" className={(isValidEmail && isValidMessage) ? "submit-enabled" : "submit-disabled"}>Submit</button>
      </form>
      {isModalVisible && (
        <div className='modal-contact'>
          <div className='modal-content'>
            <span className='close-button' onClick={closeModal}>&times;</span>
            <p>Message's in the wind.</p>
            <p>Hold tight. We'll circle back 'round when the time's right.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
